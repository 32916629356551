import moment from 'moment-timezone';
import { TranslatableField } from './TranslatableField';
import { FormDto } from './FormDto';

export enum EventType {
  Physical = 'Physical',
  Online = 'Online',
  Hybrid = 'Hybrid',
}

export const eventTypeIconMap: Record<EventType, 'users' | 'video-recorder' | 'hybrid'> = {
  [EventType.Physical]: 'users',
  [EventType.Online]: 'video-recorder',
  [EventType.Hybrid]: 'hybrid',
};

export enum EventLanguage {
  French = 'french',
  English = 'english',
  Browser = 'browser',
}

export interface EventDto {
  id: string;
  name: TranslatableField;
  organizationId: string;
  startDate: string;
  endDate: string;
  urlSegment: string;
  isRegistrationMandatoryForSubmission: boolean;
  contactEmail: string;
  language: string;
  timeZone: string;
  formattedAddress: string;
  latitude: number;
  longitude: number;
  streetNumber: string;
  route: string;
  locality: string;
  administrativeAreaLevel1: string;
  postalCode: string;
  type: EventType;
  isPrivate: boolean;
  place: TranslatableField;
  country: string;
  publishedAt: string | null;

  registrationEndDate: string;
  registrationStartDate: string;
  submissionEndDate: string;
  submissionStartDate: string;
  forms: FormDto[];
  areSubmissionsAlwaysEditableWhenAccepted: boolean;

  reviewStartDate: string;
  reviewEndDate: string;

  stripePublishableKey?: string;
  paysafePublishableKey?: string;
  paysafeEnvironment?: string;
  touchNetUPayUrl?: string;
  touchNetUPaySiteId?: number;
  globalPaymentsAppId?: string;
  globalPaymentsEnvironment?: string;
  monerisTokenizationId?: string;
  monerisEnvironment?: string;
  paypalMerchantId?: string;
  currency: string;
  receiptHeader: TranslatableField;
  gstBusinessNumber: string;
  qstBusinessNumber: string;
  vatBusinessNumber: string;
  pstBusinessNumber: string;

  withInvoicingPayments: boolean;
  invoicingPaymentInstructions: TranslatableField;

  hasCouponCodes: boolean;

  isDarkMode: boolean;
  banner: TranslatableField;
  logo: string | null;
  primaryColor: string | null;
  isVirtualPlatformActive: boolean;
}

export function getEventStatus(event: EventDto): string {
  if (event.publishedAt && moment().isAfter(moment(event.endDate))) return 'archived';
  if (event.publishedAt) return 'published';
  return 'under_construction';
}

export function getShortAddress(event: Partial<EventDto>): string {
  if (event.locality && event.country) return `${event.locality}, ${event.country}`;
  if (event.locality) return event.locality;
  return event.country || '';
}

import { pick } from 'lodash-es';
import { OrganizationCurrency } from './OrganizationDto';

export enum PackageTier {
  Free = 'free',
  Essentials = 'essentials',
  Advanced = 'advanced',
  Professional = 'professional',
}

export const packageTierIconMap: Record<PackageTier, string> = {
  [PackageTier.Free]: '',
  [PackageTier.Essentials]: 'lightbulb',
  [PackageTier.Advanced]: 'rocket',
  [PackageTier.Professional]: 'diamond',
};

export interface PackageDto {
  id: string;
  cost: number;
  currency: OrganizationCurrency;
  fee: number;
  tier: PackageTier;
  version: string;
  submissionCapacity: number;
}

export interface PackageOption {
  id: string;
  tier: PackageTier;
  cost: number;
  fee: number;
  disabled: boolean;
  current: boolean;
}

interface PreparePackageOptionParams {
  pck: PackageDto;
  renew: boolean;
  eventActivePackage?: PackageDto;
}

export function preparePackageOption({ pck, renew, eventActivePackage }: PreparePackageOptionParams): PackageOption {
  if (renew) {
    return {
      ...pick(pck, ['id', 'tier', 'cost', 'fee']),
      disabled: false,
      current: false,
    };
  }
  const eventActiveTier: PackageTier | null = eventActivePackage?.tier || null;
  const isCurrent = eventActiveTier === pck.tier;
  let isDisabled = false;
  if (
    (eventActiveTier === PackageTier.Advanced && pck.tier === PackageTier.Essentials) ||
    (eventActiveTier === PackageTier.Professional && pck.tier !== PackageTier.Professional)
  ) {
    isDisabled = true;
  }
  return {
    ...pick(pck, ['id', 'tier', 'cost', 'fee']),
    disabled: isCurrent || isDisabled,
    current: isCurrent,
  };
}


import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { FileUploadWithDateDto, fileExtensionIconMap } from '../../../services';

@Component
export default class OutputFile extends Vue {
  @Prop({ required: true }) readonly file!: FileUploadWithDateDto;
  @Prop({ type: String, default: undefined }) readonly icon!: string;

  get rootTagName() {
    return this.file.url ? 'a' : 'div';
  }

  get rootClassNames(): Record<string, boolean> {
    return { '-hover': !!this.file.url };
  }

  get rootAttributes(): Record<string, string> {
    const attributes: Record<string, string> = {};

    if (this.file.url) {
      attributes.href = this.file.url;
      attributes.target = '_blank';
      attributes.rel = 'noopener noreferrer';
      attributes.ariaLabel = `${this.$t('shared.global.view')} ${this.file.originalFileName}`;
    }

    return attributes;
  }

  get fileIcon(): string {
    const fileExtension = this.file.originalFileName.split('.').pop() || '';
    return this.icon || fileExtensionIconMap[fileExtension] || 'page';
  }

  get filePreviewUrl(): string | undefined {
    if (!this.file.type.startsWith('image') || !this.file.url) return;
    return `${this.file.url}?fit=max&w=200`;
  }
}

export interface FileUploadDto {
  id: string;
  fileName: string;
  originalFileName: string;
  size: number;
  url: string;
  type: string;
}

export interface FileUploadWithDateDto extends FileUploadDto {
  creationDate?: string;
}

export const fileExtensionIconMap: Record<string, 'image' | 'video' | 'slides' | 'page'> = {
  jpeg: 'image',
  jpg: 'image',
  png: 'image',
  gif: 'image',
  webp: 'image',
  avif: 'image',
  bmp: 'image',
  heic: 'image',
  jfif: 'image',
  svg: 'image',
  tif: 'image',
  tiff: 'image',
  emf: 'image',
  psd: 'image',
  mp4: 'video',
  webm: 'video',
  flv: 'video',
  m4v: 'video',
  mov: 'video',
  mpg: 'video',
  mpeg: 'video',
  avi: 'video',
  wmv: 'video',
  ppt: 'slides',
  pptx: 'slides',
  pps: 'slides',
  ppsx: 'slides',
  pptm: 'slides',
  potx: 'slides',
  odp: 'slides',
  key: 'slides',
  pdf: 'page',
  doc: 'page',
  docx: 'page',
  docm: 'page',
  dotx: 'page',
  txt: 'page',
  rtf: 'page',
  odt: 'page',
  pages: 'page',
  csv: 'page',
  tsv: 'page',
  xlsx: 'page',
  json: 'page',
  xml: 'page',
  mp3: 'page',
  m4a: 'page',
  aac: 'page',
  wav: 'page',
  eml: 'page',
  msg: 'page',
  srt: 'page',
  vtt: 'page',
  otf: 'page',
  ttf: 'page',
  pub: 'page',
  eps: 'page',
  ai: 'page',
  ics: 'page',
  ipynb: 'page',
  r: 'page',
  m: 'page',
  prproj: 'page',
  jpf: 'page',
  qgz: 'page',
};
